<template>
    <div class="association">
        <div class="banner">
            <img src="../assets/images/association/banner3.png" class="bimg" alt="" srcset="" width="100%"> 
        </div>
        <div class="orientation">
            <div class="orientation-content">
                您当前的位置：<a href="/">首页</a> > <a href="/">联盟介绍</a>
            </div>
        </div>
        <div class="introduce">
            <div class="introduce-content">
                <div class="description"> {{xhjs.description1}}
                </div>
              <div class="img-style">
                    <img :src="xhjs.img" alt="" width="90%">
                </div>
               <!-- <div class="description1">{{xhjs.description1}}
                </div> -->
            </div>
        </div>
        
    </div>
</template>

<script>
	import Swiper from "swiper";
	import { setTimeout } from "timers";
	import request from "../utils/request.js";
	import { apiUrl ,fileUrl} from '@/config'
export default {    
    data(){
        return {
			fileUrl:fileUrl,
			xhjs:{
				description:'',
				description1:'',
				img:''
			},
			// imgurl:"https://www.whmise.com/mise",
			queryId:"598",
			dataId:"1"
        }
    },
    methods:{
       /**协会介绍**/
       getXhxx(){
       	var that=this
    
       	request.get('/formMake/view/'+that.queryId+'/'+that.dataId).then(res =>{
       		if(res.code==0){
       		const {data}=res.data
            that.xhjs.description=data.editMData.xhjj
       		that.xhjs.description1=data.editMData.xhjs
       		if (data && data.editMData && data.editMData.tp && data.editMData.tp.length > 0) {
       		    // 获取第一个图片的URL  
       		    var imageUrl = data.editMData.tp[0].url;  
       		      
       		    // 将URL赋值给that.zzfg.img  
       		    that.xhjs.img = this.fileUrl+imageUrl;  
       		} else { 
			   that.xhjs.img=require("../assets/images/home1/xhjs1.png")
       		    console.error("No image URL found in the data.");  
       		}
       		}
       	})
       }
 },
	mounted() {
		var that=this
		that.getXhxx()
		  
	}
}
</script>
<style lang="scss" scoped>
.association{
    box-sizing: border-box;
    .banner{
        width: 100%;
        height: 300px;
        .bimg{
            width: 100%;
            height:100%;
        }
    }
    .orientation{
        background-color: #f5f5f5;
        height: 60px;   
        margin-bottom: 30px;
        .orientation-content{
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            line-height: 60px;
            color:#666666
        }
    } 
    .introduce{
        margin-bottom: 120px;
        .introduce-content{
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            .description{
                text-indent: 2em;
                line-height: 30px;
                font-size: 14px;
                color: #333333;
                font-weight: bold;
                margin-bottom: 10px;
            }
            .img-style{
                margin: 28px 0px;
                display: flex;
                justify-content: center;
            }
        }
    }
}
</style>